import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Toast } from '@ea/models';
import { Subject, takeUntil, timer } from 'rxjs';
import { FreshPaintService } from 'src/app/services/shared/freshPaint/fresh-paint.service';
declare var freshpaint: any;
@Component({
  selector: 'ea-toast',
  standalone: true,
  imports: [CommonModule, NgIf],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnDestroy {
  private _toast?: Toast;

  constructor(private readonly FB: FreshPaintService) {}
  @Input() set toast(value: Toast) {
    this._toast = value;
    this.unsubscribe$.next();
    timer(value.duration)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.dispose.emit();
      });
  }

  get message() {
    return this._toast?.message;
  }

  get type() {
    return this._toast?.type;
  }
  get isSmall() {
    return this._toast?.isSmall;
  }
  @Output() dispose = new EventEmitter<void>();
  private unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.FB.sendEvent('click', {
      Page_type: 'toaster',
      Page_name: this._toast?.message,
      Click_name: 'Button',
      Click_value: 'close',

      conversion_label: 'EnableAll',
    });
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
